<template>
  <div class="full flex-column no-expand">
    <div class="view-container no-expand" style="flex: 1; position: relative">
      <transition :name="transitionName">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </div>
    <div class="navi-container no-shrink">
      <tabbar :fixed="false" v-model="active" safe-area-inset-bottom>
        <tabbar-item icon="home-o" to="merchantBizMgr">营业管理</tabbar-item>
        <tabbar-item icon="orders-o" to="merchantMenu">菜单管理</tabbar-item>
        <tabbar-item icon="cashier-o" to="merchantOrder">餐桌管理</tabbar-item>
        <tabbar-item icon="user-o" to="merchantMe">我的店铺</tabbar-item>
      </tabbar>
    </div>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
export default {
  name: "MerchantHome",
  components: {
    Tabbar,
    TabbarItem,
  },
  data() {
    return {
      active: 0,
    };
  },
  computed: {
    transitionName() {
      if (this.active === 0) {
        return "backward";
      } else return "forward";
    },
  },
};
</script>

<style scoped>
/deep/ .van-tabbar-item--active {
  color: @mch-color;
}
</style>
